<template>
  <div class="home">
    <Navbar jenis="biasa"/>
    <div class="container pt-5">
      <v-alert
        dense
        outlined
        type="info"
      >
        hello random user, please <strong>verification</strong> you are <strong>account :)</strong>, <a href=""><u>Resend Verification</u></a>
      </v-alert>
      <v-alert
        dense
        outlined
        type="error"
        v-if="error"
      >
        Error On <b>{{ errorOn }}</b> Message: <b>{{ message }}</b>
      </v-alert>
      <table>
          <tr>
            <td>
                <section>
                  <label for="fileToUpload">
                  <i class="fa fa-camera"></i>
                  <input type="file" id="fileToUpload" style="visibility:hidden;" accept=".png,.jpg,jpeg,.PNG,.JPEG" name="fileToUpload" onchange="document.getElementById('blah').src = window.URL.createObjectURL(this.files[0])">
                  </label>
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7BXX2cI7TyRkA5bigxxWq8BNDBmrUbQDlsA&usqp=CAU" id="blah" alt="Avatar">
                </section>
                <h1 class="pt-5" style="color: #000">@{{ this.$store.state.user.user.username }}</h1>
            </td>
            <td>
                <ul>
                  <li><b>Full name</b> <input type="text" name="fname" id="fname" v-model="form.name" maxlength="100" required /> <i class="fa fa-edit" id="edit1" onclick="document.getElementById('fname').style.pointerEvents='auto';document.getElementById('fname').focus();this.style.display='none'; document.getElementById('check1').style.display='inline-block';"></i> <i class="fa fa-check" style="display:none;" id="check1" @click="change('name')" onclick="document.getElementById('edit1').style.display='inline-block';this.style.display='none';document.getElementById('fname').style.pointerEvents='none';"></i></li>
                  <li><b>Username</b> <input type="text" name="mobile" id="mobile" v-model="form.username" maxlength="10" required /> <i class="fa fa-edit" id="edit2" onclick="document.getElementById('mobile').style.pointerEvents='auto';document.getElementById('mobile').focus();this.style.display='none'; document.getElementById('check2').style.display='inline-block';"></i> <i class="fa fa-check" style="display:none;" id="check2" @click="change('username')" onclick="document.getElementById('edit2').style.display='inline-block';document.getElementById('mobile').style.pointerEvents='none';this.style.display='none';"></i></li>
                  <li><b>Email</b> <input type="email" name="email" id="email" maxlength="150" required :value="this.$store.state.user.user.email" /></li>
                  <li><b>No. Telp</b> <input type="tel" name="address" id="address" maxlength="250" v-model="form.notelp" placeholder="+628123456789" required /> <i class="fa fa-edit" id="edit3" onclick="document.getElementById('address').style.pointerEvents='auto';document.getElementById('address').focus();this.style.display='none'; document.getElementById('check3').style.display='inline-block';"></i> <i class="fa fa-check" style="display:none;" @click="change('notelp')" id="check3" onclick="document.getElementById('edit3').style.display='inline-block';document.getElementById('address').style.pointerEvents='none';this.style.display='none';"></i></li>
                  <li><b>Bio</b><br> <input type="text" name="bio" id="bio" maxlength="250" v-model="form.bio" placeholder="Write you are bio..." required /> <i class="fa fa-edit" id="edit4" onclick="document.getElementById('bio').style.pointerEvents='auto';document.getElementById('bio').focus();this.style.display='none'; document.getElementById('check4').style.display='inline-block';"></i> <i class="fa fa-check" style="display:none;" id="check4" @click="change('bio')" onclick="document.getElementById('edit4').style.display='inline-block';document.getElementById('bio').style.pointerEvents='none';this.style.display='none';"></i></li>
                </ul>
            </td>
          </tr>
      </table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/layouts/Navbar.vue'
import $ from 'jquery'
import axios from 'axios'

export default {
  name: 'Home',
  data() {
    return {
      data: null,
      error: false,
      form: {
        name: null,
        username: null,
        bio: null,
        notelp: null,
      },
      message: null,
      errorOn: null
    }
  },
  components: {
    Navbar,
  },
  mounted() {
    window.addEventListener('scroll',this.scrollDulu())
    this.form.name = this.$store.state.user.user.name
    this.form.username = this.$store.state.user.user.username
    this.form.bio = this.$store.state.user.user.bio
    this.form.notelp = this.$store.state.user.user.notelp
  },
  methods: {
    scrollDulu() {
      $(window).bind('scroll', function() {
          if ($(window).scrollTop() >= 53) {
            $('.fixed-top').addClass('fixedin');
          }
          else {
            $('.fixed-top').removeClass('fixedin');
          }
      });
    },
    change(name) {
      let data = ''
      switch (name) {
        case 'name':
          data = {
            name: this.form.name,
            me: 'name'
          }
          axios.post('auth/change', data);
          this.$store.state.user.user.name = this.form.name
          break;
        case 'username':
          data = {
            username: this.form.username,
            me: 'username'
          }
          axios.post('auth/change', data).catch(err => {
            this.errorOn = 'username'
            this.error = true
            this.message = err.response.data.message
            return;
          });
          this.$store.state.user.user.username = this.form.username
          this.error = false
          break;
        case 'notelp':
          data = {
            notelp: this.form.notelp,
            me: 'notelp'
          }
          axios.post('auth/change', data);
          this.$store.state.user.user.notelp = this.form.notelp
          break;
        case 'bio':
          data = {
            bio: this.form.bio,
            me: 'bio'
          }
          axios.post('auth/change', data);
          this.$store.state.user.user.bio = this.form.bio
          break;
        default:
          break;
      }
    } 
  }
}
</script>
<style scoped>
html {
scroll-behavior: smooth;
  }

  body{
  margin:0;
  overflow-x:hidden;
  background:#1e1e1e;
  font-family: 'Oswald', sans-serif;
  }

  a,li,em,button,input,textarea,select{
  text-decoration:none;
  list-style:none;
  font-style:normal;
  outline:none !important;
  transition:0.5s;
  resize:none;
  }

  ul{
  position:relative;
  left:-20px;
  }

  ul li{
  padding:20px 0;
  color:gray;
  border-bottom:1px solid #000;
  }

b{
color:#fff;
}

.btn{
background:#7ea4b3;
padding:10px;
border:0;
outline:none;
color:#000;
display:block;
width:60px;
margin:10px 0;
text-align:Center;
border-radius:5px;
cursor:pointer;
}


.fa{
color:#7ea4b3;
cursor:pointer;
}






.container{
width:90%;
margin:10vh auto;
}

.container table{
width:100%;
}

.container table td{
margin:30px;
border-radius:5px;
border: 1px solid #000;
padding:30px;
color:#fff;
vertical-align:top;
}

.container table td:nth-child(1){
text-align:Center;
}

.container table td:nth-child(2) .fa{
float:right;
}

.container table td:nth-child(2) input{
background:none;
outline:none;
border:0;
color:gray;
width:65%;
pointer-events:none;
}

.container table td:nth-child(1) section{
position:relative;
width:200px;
height:200px;
margin:5vh auto;
}


.container table td:nth-child(1) .fa{
    position: absolute;
    right: 76px;
    font-size: 2em;
    bottom: 42px;
    border-radius: 50%;
    background: #000;
}

.container table td img{
width:200px;
height:200px;
border-radius:50%;
border: 5px solid #000;
}

.container table td h3{
color:gray;
font-weight:normal;
}

.container table .section2{
text-align:left;
}

.container table .section2 label{
display:block;
margin:10px 0;
text-align:left;
} 

.container table .section2 select{
background:#1e1e1e;
width:100%;
padding:19px;
border:0;
outline:none;
color:gray;
}

.container table .section2 input{
background:#1e1e1e;
width:90%;
padding:20px;
color:gray;
border:0;
outline:none;
}

.container table .section2 .quantityselector{
height:80px;
border-bottom:0px;
}

.container table .section2 .quantityselector section{
display:inline-block !important;
width:45%;
margin:0 10px;
}

.container table textarea{
width:90%;
resize:none;
outline:none;
border:0;
background:#1e1e1e;
color:gray;
padding:20px;
}



.container .inframe .card{
width:100%;
height:40vh;
position:relative;
overflow:hidden;
display:block;
margin:10px 0;
}

.container .inframe .card img{
width:100%;
height:40vh;
border-radius:0;
}

.card_content{
height:0;
position:absolute;bottom:0;
width:100%;background-color:rgba(1,1,1,0.8);
transition:0.5s;
}

.card_content ul li{
padding:5px;
font-size:13px;
}

.card_content h3{
text-align:Center;
color:#fff;
}

.inframe .card:hover > .card_content{
height:40vh;
}

.card_content .btn{
font-size:12px;
width:100px;
display:inline-block;
margin:0 10px;
}




@media (max-width:820px){
.container{
width:100%;
}
.container table td{
display:block;
width:90%;
margin:0px;
}
.container table .section2 .quantityselector section{
width:41%;
margin:0px 10px;
}
.container table .section2 ul{
position:relative;
left:-40px;
}

}








.alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
  opacity: 1;
  transition: opacity 0.6s;
  margin-bottom: 15px;
}

.alert.success {background-color: #04AA6D;}
.alert.info {background-color: #2196F3;}
.alert.warning {background-color: #ff9800;}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

b {
  color: #000;
}

.closebtn:hover {
  color: black;
}
</style>
